<template>
  <ion-page>
    <ion-header>
      <ion-toolbar class="numdle-background-color ion-text-center">
        <ion-buttons @click="openMenu()" slot="start">
          <ion-icon color="secondarynumdle" :icon="menu" slot="start"></ion-icon>
        </ion-buttons>
        <img alt="logo" height="40" src="/assets/logo_peque_numdle.png">
        <ion-title size="small">{{ $t("message.dailyGame") }}</ion-title>

        <ion-buttons slot="end">
          <ion-button>
            <ion-icon color="secondarynumdle" slot="icon-only" :icon="personCircle"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">

      <GameView v-on:onGameFinished="gameFinished" v-on:onUsedComodin="decrementComodin" :comodines="this.comodines" pageTitle="Juego" :game="this.game" v-if="started && !played">
      </GameView>
      <ResultView v-if="started && played">
      </ResultView>
      <div v-if="!started" class="Container">
        <img alt="logo" height="200" src="/assets/robot.gif">
        <ion-button class="startDialog" id="open-modal" expand="block">{{ $t("message.start") }}</ion-button>
        <ion-modal ref="modal" trigger="open-modal" class="ion-padding">
          <ion-content color="secondarynumdle" back>
            <ion-toolbar>
              <ion-title color="secondarynumdle">{{ $t("message.advise") }}</ion-title>
            </ion-toolbar>
            <br/>
            <br/>
            <ion-label color="primarynumdle" >
              <h2>{{ $t("message.welcome1") }}</h2>
            </ion-label>
            <br/>
            <br/>
            <ion-button class="startGame" @click="startGame()" id="open-modal" expand="block">{{ $t("message.start") }}</ion-button>
          </ion-content>
        </ion-modal>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import {
  IonPage, IonHeader, IonToolbar, IonButtons, IonIcon, IonButton, IonModal,
  menuController
} from "@ionic/vue";
import { menu } from "ionicons/icons";
import { personCircle } from 'ionicons/icons';
import GameView from "./GameView.vue";
import ResultView from "./ResultView.vue";
import GameService from "../application/services/gameService";
import RatingService from "../application/services/ratingService";

const gameService = new GameService();
const ratingService = new RatingService();

export default {
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonIcon,
    IonButton,
    IonModal,
    GameView,
    ResultView
},
  props: ["pageTitle"],
  data() {
    return {
      menu,
      started: false,
      played: false,
      game: {},
      level: "",
      comodines: 0
    };
  },
  methods: {
    openMenu() {
      menuController.open("app-menu");
    },
    async startGame() {
      const generatedGame = await gameService.generateDailyGame();
      this.game = generatedGame;
      this.$refs.modal.$el.dismiss();
      this.started = true;
    },
    async gameFinished(status , steps, time, statistics) {
      await gameService.saveAdvancedStatistics(steps, time, statistics);
      this.game.finished = true;
      this.game.won = status;
      this.game.operationsWon = steps;
      this.game.time = time;
      await gameService.setGamePlayed(this.game);
      this.played = true;
    },
    async decrementComodin() {
      const statistics = await gameService.getStatistics();
      await gameService.updateComodines(statistics.championsLeague - 1);
    }
  },
  setup() {
    return { personCircle };
  },
  async mounted() {
    const statistics = await gameService.getStatistics();
    this.comodines = statistics.championsLeague;
    gameService
      .isCurrentGamePlayed()
      .then((res) => {
        if (res) {
          this.played = true;
          this.started = true;
        } else {
          ratingService.getLevel().then((res) => {
            this.level = res;
          });
        }
      })
    .catch((e) => {
      alert(e);
    });
  },
};
</script>
<style scoped>
.Container {
  padding-top: 10%;
  text-align: center;
}

.numdle-background-color {
  --background: #07305d;
  --color: white;

  --border-bottom: 4px #eaa159;

  --min-height: 60px;

}

ion-title {
  --color: #eaa159;
}

ion-content {
  --ion-background-color: #07305d;
}

.startDialog {
  --background: #fff;
  --background-hover: #fff;
  --background-activated: #fff;
  --background-focused: #fff;

  --color: #07305d;

  --border-radius: 10px;
  --border-color: #eaa159;
  --border-style: solid;
  --border-width: 3px;

  --box-shadow: 0 2px 6px 0 rgb(0, 0, 0, 0.25);

  --ripple-color: #eaa159;

  --padding-top: 10px;
  --padding-bottom: 10px;

  margin-top: 0px;
  margin-right: 20px;
  margin-left: 20px;
  height: 80px;
  font-size: 20px;

}

.startGame {
  --background: #fff;
  --background-hover: #fff;
  --background-activated: #fff;
  --background-focused: #fff;

  --color: #eaa159;

  --border-radius: 10px;
  --border-color: #07305d;
  --border-style: solid;
  --border-width: 3px;

  --box-shadow: 0 2px 6px 0 rgb(0, 0, 0, 0.25);

  --ripple-color: #07305d;

  --padding-top: 10px;
  --padding-bottom: 10px;

  margin-top: 0px;
  margin-right: 20px;
  margin-left: 20px;
  height: 60px;
  font-size: 20px;

}

ion-modal {
  --height: 50%;
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

ion-modal::part(backdrop) {
  background: rgba(209, 213, 219);
  opacity: 1;
}

ion-modal ion-toolbar {
  --background: #07305d;
  --color: white;
}
</style>