<template>
  <div class="calculator">
    <button @click="add('V')" class="box eight">V</button>
    <button @click="add('C')" class="box five">C</button>
    <button @click="add('D')" class="box six">D</button>
    <button @click="add('I')" class="box seven">I</button>
    <button @click="add('L')" class="box four">L</button>
    <button @click="add('X')" class="box nine">X</button>
    <button :key="refresh" v-if="currentComodines > 0" @click="add('∞')" class="box one disable-dbl-tap-zoom">∞</button>
    <button @click="add('M')" class="box two">M</button>
    <button @click="deleteCharacter" class="box delete">←</button>
    <button @click="send" class="box intro">OK</button>

  </div>
</template>
<script >

export default {
  name: 'RomanNumericKeyboard',
  data () {
    return {
      currentValue: this.initValue,
      currentComodines: this.comodines,
      refresh: false
    }
  },
  props: {
    initValue: String
  },
  methods: {
    send () {
      let valueToValidate = this.currentValue;
      this.currentValue = '';

      if (this.currentComodines > 0 && valueToValidate === '∞') {
        this.currentComodines = this.currentComodines - 1;
        this.$emit('onNewComodin');
      }
      this.refresh = !this.refresh;
      this.$emit('onNewNumber', valueToValidate);
    },
    deleteCharacter () {
      let numberString = this.currentValue.toString();
      if (numberString.length>0) {
        this.currentValue = this.currentValue.toString().slice(0, -1);
        this.$emit('onDeleteNumber', this.currentValue);
      }
    },
    clean () {
      this.currentValue = '';
    },
    add (value) {
      this.currentValue = this.currentValue + value;
      this.$emit('onNewDigit', this.currentValue);
    }
  },
  created() { 

    }
}
</script>
<style>
.calculator {
    display: grid;
    grid-gap: 2px;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    color: #444;
    padding-right: 10px;
  }

.box {
  background-color: #f0bb86;
  color: #07305d;
  border-radius: 4px;
  padding: 20px;
  font-size: 140%;

}
.box:active {
  background-color:white;    
}

.eight {
  grid-area: 1 / 2 / 2 / 3;
}
.five {
  grid-area: 2 / 2 / 3 / 3;
}
.six {
  grid-area: 2 / 3 / 3 / 4;
}
.seven {
  grid-area: 1 / 1 / 2 / 2;
}
.four {
  grid-area: 2 / 1 / 3 / 2;
}
.nine {
  grid-area: 1 / 3 / 2 / 4;
}
.one {
  grid-area: 3 / 1 / 3 / 2;
}
.two {
  grid-area: 3 / 2 / 3 / 3;
}
.three {
  grid-area: 3 / 3 / 3 / 4;
}
.dot {
  grid-area: 4 / 3 / 4 / 3;
}
.zero {
  grid-area: 4 / 2 / 4 / 3;
}
.delete {
  grid-area: 1 / 4 / 1 / 4;
}

.intro {
  grid-area: 3 / 4 / 3 / 4;
  color: green;
}
.minus {
  grid-area: 2 / 4 / 3 / 4;
}
.button_keyboard {
  display: inline-block;   /* this shouldn't be needed on a button though */
  width: 100%;
}
</style>