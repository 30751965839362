<template>
  <ion-page>    
  <ion-header>
      <ion-toolbar class="numdle-background-color ion-text-center">
          <ion-buttons @click="openMenu()" slot="start">
              <ion-icon color="secondarynumdle" :icon="menu" slot="start"></ion-icon>
          </ion-buttons>
          <img alt="logo" height="40"  src="/assets/logo_peque_numdle.png" > 
          <ion-buttons slot="end">
              <ion-button>
                  <ion-icon color="secondarynumdle" slot="icon-only" :icon="personCircle"></ion-icon>
              </ion-button>
          </ion-buttons>
      </ion-toolbar>
  </ion-header>
  <ion-content :fullscreen="true">
    
    <ion-card @click="launchUrl('https://resilient-fudge-e9eb14.netlify.app/');" button>
      <img alt="Enredo de letras" src="/assets/numdle_enredo_letras.jpg" />

      <ion-card-header>
        <ion-card-title>Enredo de letras (beta)</ion-card-title>
        <ion-card-subtitle>Si te gusta la nueva prueba de saber y ganar con este juego la puedes entrenar</ion-card-subtitle>
      </ion-card-header>

      <ion-card-content>
        Recordamos las reglas <br>
        - En la rejilla de 4x4 hay una palabra de 10 letras que tienes que encontrar <br>
        - Las letras están adyacentes (arriba, abajo, derecha, izquierda y esquinas) <br>
        - Cada 10 segundos desaparece una letra que no forma parte de la palabra. <br>
        - A los 45 segundos aparece la letra inicial de la palabra <br>
        - Empiezas con 300 puntos, a los 30 segundos bajas a 200 puntos y a los 60 segundos a 100 puntos
      </ion-card-content>
    </ion-card>
    
    <ion-card @click="menuNavigation('/daily')">
      <img alt="Numdle original" src="/assets/numdle_original.png" />

      <ion-card-header>
        <ion-card-title>{{ $t("message.dailyGame") }}</ion-card-title>
        <ion-card-subtitle>{{ $t("message.dailyGameTitle") }}</ion-card-subtitle>
      </ion-card-header>

      <ion-card-content>
        {{ $t("message.dailyGameMessage") }}
      </ion-card-content>
    </ion-card>

    <ion-card @click="menuNavigation('/kids')">
      <img alt="Numdle training" src="/assets/numdle_kids.png" />

      <ion-card-header>
        <ion-card-title>{{ $t("message.numdleKids") }}</ion-card-title>
        <ion-card-subtitle>{{ $t("message.numdleKidsTitle") }}</ion-card-subtitle>
      </ion-card-header>

      <ion-card-content>
        {{ $t("message.numdleKidsMessage") }}
      </ion-card-content>

    </ion-card>

    <ion-card @click="menuNavigation('/training')">
      <img alt="Numdle training" src="/assets/numdle_training.png" />

      <ion-card-header>
        <ion-card-title>{{ $t("message.training") }}</ion-card-title>
        <ion-card-subtitle>{{ $t("message.trainingTitle") }}</ion-card-subtitle>
      </ion-card-header>

      <ion-card-content>
        {{ $t("message.trainingMessage") }}
      </ion-card-content>

    </ion-card>

    <!--ion-card @click="menuNavigation('/league')">
      <img alt="Silhouette of mountains" src="assets/numdle_league.jpg" />

      <ion-card-header>
        <ion-card-title>Numdle League (A partir de 2023)</ion-card-title>
        <ion-card-subtitle>La liga de Numdle</ion-card-subtitle>
      </ion-card-header>

      <ion-card-content>
        Participa en la liga de Numdle. Cada día una calculadora y con clasificaciones por jornada y temporada. Sé el más rápido jugando al Numdle y gana la Numdle League. 
      </ion-card-content>

    </ion-card-->

    <ion-card @click="menuNavigation('/romans')">
      <img alt="Silhouette of mountains" src="assets/numdle_romanos.jpg" />

      <ion-card-header>
        <ion-card-title>{{ $t("message.romans") }}</ion-card-title>
        <ion-card-subtitle>{{ $t("message.romansTitle") }}</ion-card-subtitle>
      </ion-card-header>

      <ion-card-content>
        {{ $t("message.romansMessage") }}
      </ion-card-content>

    </ion-card>

    <ion-card @click="menuNavigation('/binaries')">
      <img alt="Silhouette of mountains" src="assets/numdle_binarios.png" />

      <ion-card-header>
        <ion-card-title>{{ $t("message.binaries") }}</ion-card-title>
        <ion-card-subtitle>{{ $t("message.binariesTitle") }}</ion-card-subtitle>
      </ion-card-header>

      <ion-card-content>
        {{ $t("message.binariesMessage") }}
      </ion-card-content>

    </ion-card>

</ion-content>
  </ion-page>
</template>
<script>
import {
  IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonPage,IonHeader,IonToolbar,IonButtons,IonIcon,
  menuController,
} from "@ionic/vue";
import { menu } from "ionicons/icons";
import { personCircle } from 'ionicons/icons';

export default {
  components: {
    IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonPage,IonHeader,IonToolbar,IonButtons,IonIcon,
  },
  props: ["pageTitle"],
  data() {
    return {
      menu,
    };
  },
  methods: {
    openMenu() {
      menuController.open("app-menu");
    },
    menuNavigation(url){
      this.$router.push(url);
    },
    launchUrl(url){
      window.open(url, '_blank');
    }
  },
  setup() {
    return { personCircle };
  }
};
</script>
<style scoped>
.numdle-background-color{
  --background: #07305d;
  --color: white;

  --border-bottom: 4px #eaa159;

  --min-height: 60px;

}

ion-title {
--color: #eaa159;
}
#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}
.numdle-background-color{
  --background: #07305d;
  --color: white;

  --border-bottom: 4px #eaa159;

  --min-height: 60px;

}
.title-image {
    margin-top: 8px;
    height: 27px;
}

ion-card {
    --background: #eaa159;
    --color: #07305d;
  }

  ion-card-title {
    --color: #07305d;
  }

  ion-card-subtitle {
    --color: #07305d;
  }

  /* iOS places the subtitle above the title */
  ion-card-header.ios {
    display: flex;
    flex-flow: column-reverse;
  }
</style>