<template>
  <div>
    <div v-if="level === 'stars'" class="headerStars">
      <p style="color:black">🦸 {{ $t("message.levelStars") }} 🦸‍♀️</p>
    </div>
    <div v-if="level === 'gold'" class="headerGold">
      <p style="color:black">🥇 {{ $t("message.levelGold") }} 🥇</p>
    </div>
    <div v-if="level === 'silver'" class="headerSilver">
      <p style="color:black">🥈 {{ $t("message.levelSilver") }} 🥈</p>
    </div>
    <div v-if="level === 'bronze'" class="headerBronze">
      <p style="color:black">🥉 {{ $t("message.levelBronze") }} 🥉</p>
    </div>
    <h1 v-if="this.game.won == true">
      🏆 {{ $t("message.challengeCompleted") }} 🏆
    </h1>
    <h1 v-if="this.game.won == false">
      {{ $t("message.challengeNotCompleted") }}
    </h1>
    <h2>{{ $t("message.date") }} {{ dateFormated }}</h2>
    <div class="starContainer">
      {{ result }}
    </div>
    <h3>{{ $t("message.time") }} {{ this.game.time }}</h3>
    <ShareNetwork class="social" network="twitter" url="https://www.numdle.net" :title="tweet" hashtags="numdle"
      twitter-user="NumdleOriginal">
      <i class="fab fah fa-lg fa-twitter shareImage"></i>
    </ShareNetwork>
    <ShareNetwork class="social" network="whatsapp" url="https://www.numdle.net" :title="tweet" hashtags="numdle">
      <i class="fab fah fa-lg fa-whatsapp shareImage"></i>
    </ShareNetwork>
    <ShareNetwork class="social" network="facebook" url="https://www.numdle.net" :title="tweet" hashtags="numdle">
      <i class="fab fah fa-lg fa-facebook shareImage"></i>
    </ShareNetwork>
    <ShareNetwork class="social" network="telegram" url="https://www.numdle.net" :title="tweet" hashtags="numdle">
      <i class="fab fah fa-lg fa-telegram shareImage"></i>
    </ShareNetwork>

    <p>{{ $t("message.anotherChallenge") }}</p>
  </div>

</template>
<script>
import GameService from "../application/services/gameService";
import RatingService from "../application/services/ratingService";
import HistoryService from "../application/services/historyService";

const gameService = new GameService();
const ratingService = new RatingService();
const historyService = new HistoryService();

export default {
  name: "ResultView",
  data() {
    return {
      game: {
        date: "-",
        won: true,
        time: 0,
        operationsWon: 0,
        operations: [],
      },
      showModel: false,
      statistics: {
        played: 0,
        won: 0,
        average: 0,
        negativeStrike: 0,
        positiveStrike: 0,
        championsLeague: 0,
      },
      level: "",
      initialLevel: "",
    };
  },
  components: {
  },
  methods: {
    showStatistics() {
      this.showModel = true;
    },
    async onChange(event) {
      const file = await event.target.files[0];
      let reader = new FileReader();
      reader.onload = () => {
        let binary = '';
        let bytes = new Uint8Array( reader.result );
        let len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode( bytes[ i ] );
        }
        historyService.uploadFile(window.btoa( binary )).then(() => {
          location.reload();
        });
      };
      reader.readAsArrayBuffer(file);
    },
    copyResult() {
      navigator.clipboard.writeText(this.tweet);
      this.$toast.success(this.$t("message.copied"), {
        position: "top",
        duration: 1000,
      });
    },
    downloadHistory() {
      historyService.downloadFile().then((fileContent) => {
        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + fileContent);
        element.setAttribute('download', 'history.numdle');
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      });
    },
    uploadHistory() {
      this.$toast.success('Cargar historial', {
        position: "top",
        duration: 1000,
      });
    },
    showConfetti() {
      this.$confetti.start();
      this.$confetti.update({
        particles: [
          {
            type: "circle",
            dropRate: 6,
          },
        ],
        particlesPerFrame: 1,
        windSpeedMax: 0,
      });
    },
    hideConfetti() {
      this.$confetti.stop();
    },
  },
  mounted() {
    gameService
      .getGamePlayed()
      .then((res) => {
        this.game = res;
        ratingService.getLevel().then((res) => {
          this.level = res;
          this.initialLevel = this.level;
          gameService.nextLevel(this.level).then((newLevel) => {

            this.level = newLevel;
            if (
              (this.initialLevel === "gold" && this.level === "silver") ||
              (this.initialLevel === "silver" && this.level === "bronze") ||
              (this.initialLevel === "stars" && this.level === "gold")
            ) {
              this.$toast.error(this.$t("message.downgrade"), {
                position: "top",
              });
            }
            if (
              (this.initialLevel === "silver" && this.level === "gold") ||
              (this.initialLevel === "bronze" && this.level === "silver") ||
              (this.initialLevel === "gold" && this.level === "stars")
            ) {
              this.showConfetti();
            }
          });
        });
        gameService.getStatistics().then((statistics) => {
          this.statistics = statistics;
        });
      })
      .catch((e) => {
        alert(e);
      });
  },
  computed: {
    // ⏱️  📉
    tweet: function () {
      let parsed = new Date(Date.parse(this.game.date));
      let date = parsed.toLocaleDateString("es-ES");
      let levelIcon;
      if (this.level === "gold") {
        levelIcon = "🥇";
      } else if (this.level === "silver") {
        levelIcon = "🥈";
      } else if (this.level === "bronze") {
        levelIcon = "🥉";
      } else if (this.level === "stars") {
        levelIcon = "🦸 🦸‍♀️";
      }
      let resultTweet =
        "𝗡𝗨𝗠𝗗𝗟𝗘 (" + date + ") " + levelIcon + " \n\n" + this.result + "\n\n";
      if (this.game.won) {
        resultTweet =
          resultTweet +
          "\n🏆  " +
          this.$t("message.challengeCompleted") +
          " " +
          this.$t("message.time") +
          this.game.time +
          " 🏆\n\n";
      }
      return resultTweet;
    },
    dateFormated: function () {
      const parsed = new Date(Date.parse(this.game.date));
      return parsed.toLocaleDateString("es-ES");
    },
    result: function () {
      let result = "";
      for (let i = 0; i < this.game.operationsWon; i++) {
        result = result + "🟢";
        // result = result + "🟣";
      }
      for (
        let i = 0;
        i < this.game.operations.length - this.game.operationsWon;
        i++
      ) {
        result = result + "⚪️";
      }
      return result;
    },
  },
};
</script>

<style scoped>
.shareImage {
  margin-top: 0.5em;
  font-size: 40px;
}

.social {
  padding: 20px;
}

a {
  color: #42b983;
}

h4 {
  color: #f0bb86;
  font-family: Helvetica, Arial;
}

h3 {
  color: #f0bb86;
  font-family: Helvetica, Arial;
}

h1 {
  color: #eaa159;
  font-family: Helvetica, Arial;
}

h2 {
  color: #eaa159;
  font-family: Helvetica, Arial;
}

p {
  border: #eaa159 2px solid;
  color: #eaa159;
  size: 30px;
  font-size: 18px;
}

.headerSilver {
  width: 100%;
  background-color: #cdd6dd;
  color: black;
  text-align: center;
}

.mbtnCopy {
  color: #07305d;
  background-color: #48b886;
  border: 0;
  cursor: pointer;
  text-align: center;
  height: 30px;
  width: 200px;
  font-size: 16px;
  margin-bottom: 10px;
}

.headerStars {
  width: 100%;
  background-color: lightgray;
  color: black;
  text-align: center;
}

.headerGold {
  width: 100%;
  background-color: #fdab42;
  color: black;
  text-align: center;
}

.headerBronze {
  width: 100%;
  background-color: #fd8a46;
  color: black;
  text-align: center;
}

.starContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

button.mbtnReverse {
  color: #07305d;
  background-color: #eaa159;
  border: 0;
  cursor: pointer;
  text-align: center;
  height: 30px;
  width: 100px;
  font-size: 16px;
  margin-bottom: 10px;
}

::v-deep(.vtl-table .vtl-thead .vtl-thead-th) {
  color: #fff;
  background-color: #07305d;
  border-color: #07305d;
}

::v-deep(.vtl-table td),
::v-deep(.vtl-table tr) {
  border: none;
}

::v-deep(.vtl-paging-info) {
  color: rgb(172, 0, 0);
}

::v-deep(.vtl-paging-count-label),
::v-deep(.vtl-paging-page-label) {
  color: rgb(172, 0, 0);
}

::v-deep(.vtl-paging-pagination-page-link) {
  border: none;
}
</style>
