


import GameRepository from '../repository/gameRepository'
const gameRepository = new GameRepository()

export default class RatingService {
  getLevel () {
    return (async () => {
      let level = await gameRepository.getLevel();
      return level;
    })()
  }

  setLevel (level) {
    return (async () => {
      await gameRepository.setLevel(level);
      return;
    })()
  }}
