import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import MasterLayout from './components/MasterLayout.vue'
import { createI18n } from 'vue-i18n';
import KProgress from 'k-progress-v3'
import VueSocialSharing from 'vue-social-sharing'
import VueConfetti from 'vue-confetti'
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons';
library.add(fas);
import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(fab);
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(far);
import { dom } from "@fortawesome/fontawesome-svg-core";
dom.watch();

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

const messages = {
  en: {
    message: {
      humanCalculator: 'The human calculator',
      start: 'Start',
      wrongResult: 'Wrong',
      correctResult: 'Correct',
      challengeCompleted: 'Challenge completed!',
      challengeNotCompleted: 'Challenge not completed!',
      date: 'Date: ',
      time: 'Time (sec): ',
      shareTwitter: 'Share on Twitter',
      anotherChallenge: 'Another challenge tomorrow!',
      twitterMessageOk1: 'I have solved the numdle for ',
      twitterMessageOk2: ' in ',
      twitterMessageOk3: ' seconds! How much do you do it?',
      twitterMessageKo1: 'I could not solve the numdle for ',
      twitterMessageKo2: ' Are you capable? ',
      operationsWon: 'Succesful operations: ',
      timeOver: 'Your time is over',
      helpClose: 'Close',
      help1: 'A mathematical operation (addition, subtraction, multiplication or division) will appear on the screen.',
      help2: 'Introduzca en el teclado el resultado y pulsa OK.Enter the result on the keyboard and press OK.',
      help3: 'If you make a mistake, nothing happens. You can try as many times as necessary',
      help4: 'There are 7 operations per challenge and you have 45 seconds to complete it.',
      welcome1: 'There are 3 levels of difficulty. To level up you have to do 3 consecutive daily challenges. If you dont pass 3 challenges in a row you will lose a level.',
      welcome2: 'You have 75 seconds in bronze, 60 in silver and 45 in gold.',
      welcome3: 'You have 120 seconds to complete the challenge',
      deleteButton: 'Delete',
      statistics1: 'Statistics since 20/02/2022',
      statistics2: 'Challenges aplyed: ',
      statistics3: 'Challenges won: ',
      statistics4: 'Average time (sec): ',
      statistics6: 'Negative streak: ',
      copy: 'Copy result',
      copied: 'Result copied',
      levelStars: 'Super Hero level',
      levelGold: 'Gold level',
      levelSilver: 'Silver level',
      levelBronze: 'Bronze level',
      coffee: 'Offer me a coffee',
      kids: 'Kids version',
      kidsOkMessage: 'Challenge completed!',
      kidsKo: 'Upss',
      kidsKoMessage: 'Next time will be better!',
      kidsBack: 'Back',
      homeView: 'Home',
      dailyGame: 'Daily Game',
      enredoLetras: 'Enredo de Letras (beta)',
      dailyGameTitle: 'The usual Numdle',
      dailyGameMessage: 'Play the daily challenge and share your result on social networks',
      numdleKids: 'Numdle for Kids',
      numdleKidsTitle: 'Numdle for everyone',
      numdleKidsMessage: '',
      training: 'Training',
      trainingTitle: 'Train your mental arithmetic',
      trainingMessage: 'Play as many challenges as you want and train your mental arithmetic. You can choose the time to test your mental agility.',
      romans: 'Romans',
      romansTitle: 'The romans of Numdle',
      romansMessage: 'Train the Roman numerals without having to wait for the idus of each month',
      binaries: 'Binaries',
      binariesTitle: 'The binaries of Numdle',
      binariesMessage: 'For the geeks who enjoyed binary Fridays',
      myStatistics: 'My statistics',
      sec120: '120 seconds',
      sec60: '60 seconds',
      sec45: '45 seconds',
      sec30: '30 seconds',
      sec20: '20 seconds',
      selectTime: 'Select time',
      operations: 'operations in',
      seconds: 'seconds',
      statistics: 'My statistics',
      advise: 'Advise'
    }
  },
  es: {
    message: {
      humanCalculator: 'La calculadora humana',
      start: 'Empezar',
      wrongResult: 'Incorrecto',
      correctResult: 'Correcto',
      challengeCompleted: '¡Reto superado!',
      challengeNotCompleted: 'Reto no superado',
      date: 'Fecha: ',
      time: 'Tiempo (seg): ',
      shareTwitter: 'Compartir en Twitter',
      anotherChallenge: '¡Mañana otro reto diferente!',
      twitterMessageOk1: '¡He resuelto el numdle del ',
      twitterMessageOk2: ' en ',
      twitterMessageOk3: ' segundos! ¿En cuánto lo haces tú?',
      twitterMessageKo1: 'No he podido resolver el numdle del ',
      twitterMessageKo2: ' ¿Tú eres capaz? ',
      operationsWon: 'Operaciones acertadas:',
      timeOver: 'Se te acabó el tiempo',
      helpClose: 'Cerrar',
      copy: 'Copiar resultado',
      copied: 'Resultado copiado',
      help1: 'Te aparecerá en la pantalla una operación matemática (suma, resta, multiplicación o división).',
      help2: 'Introduce en el teclado el resultado y pulsa OK.',
      help3: 'Si te equivocas no pasa nada. Puedes intentarlo las veces que sean necesarias.',
      help4: 'Hay 7 operaciones por reto y tienes {{ $t("message.sec45") }} para superarlo.',
      welcome1: 'Hay 4 niveles de dificultad. Para subir de nivel tienes que hacer 3 retos diarios consecutivos. Si no superas 3 retos seguidos bajarás de nivel.',
      welcome2: 'Tienes 75 segundos en bronce, 60 en plata y 45 en oro.',
      welcome3: 'Tienes {{ $t("message.sec120") }} para superar el reto',
      deleteButton: 'Borrar',
      statistics1: 'Estadísticas desde 20/02/2022',
      statistics2: 'Retos jugados: ',
      statistics3: 'Retos ganados: ',
      statistics4: 'Media ganados (sg): ',
      statistics5: 'Racha positiva: ',
      statistics6: 'Racha negativa: ',
      levelStars: 'Super Hero level',
      levelGold: 'Nivel oro',
      levelSilver: 'Nivel plata',
      levelBronze: 'Nivel bronce',
      downgrade: 'Bajas de nivel',
      coffee: 'Ofrézcame un café',
      kids: 'Versión para niños',
      kidsOk: '¡Buen trabajo!',
      kidsOkMessage: 'Reto superado',
      kidsKo: '¡Vaya!',
      kidsKoMessage: 'La próxima vez te saldrá mejor',
      kidsBack: 'Volver',
      homeView: 'Home',
      dailyGame: 'Reto diario',
      enredoLetras: 'Enredo de letras (beta)',
      dailyGameTitle: 'El Numdle de siempre',
      dailyGameMessage: 'Juega al reto diario y comparte tu resultado en redes sociales',
      numdleKids: 'Numdle para niños',
      numdleKidsTitle: 'Numdle para todos',
      numdleKidsMessage: '',
      training: 'Entrenamiento',
      trainingTitle: 'Entrena tu cálculo mental',
      trainingMessage: 'Juega tantos retos como quieras y entrena tu cálculo mental. Puedes elegir el tiempo para probar tu agilidad mental.',
      romans: 'Romanos',
      romansTitle: 'Los romanos de Numdle',
      romansMessage: 'Entrena los números romanos sin tener que esperar a que sean los idus de cada mes',
      binaries: 'Binarios',
      binariesTitle: 'Los binarios de Numdle',
      binariesMessage: 'Para los frikis que disfrutaban de los viernes de binarios',
      myStatistics: 'Mis estadísticas',
      sec120: '120 segundos',
      sec60: '60 segundos',
      sec45: '45 segundos',
      sec30: '30 segundos',
      sec20: '20 segundos',
      selectTime: 'Selecciona tiempo',
      operations: 'operaciones en',
      seconds: 'segundos',
      statistics: 'Mis estadísticas',
      advise: 'Aviso'

    } 
  }
}

const i18n = createI18n({
  locale: window.navigator.language, // set locale
  fallbackLocale: 'en', // set fallback locale
  messages, 
})
const app = createApp(App)
  .use(IonicVue)
  .use(i18n)
  .use(router)
  .use(VueSocialSharing)
  .use(VueConfetti)
  .component('font-awesome-icon', FontAwesomeIcon)
  .component('k-progress', KProgress);

app.component('master-layout', MasterLayout);
  
router.isReady().then(() => {
  app.mount('#app');
});