<template>
    <ion-menu menu-id="app-menu" content-id="main-content">
      <ion-header>
        <ion-toolbar class="numdle-background-color ion-text-center">
          <ion-title>Menú</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list>
          <ion-item button @click="menuNavigation('/home')">
            <ion-icon :icon="homeOutline" color="secondarynumdle" slot="start"></ion-icon>
            <ion-label color="primarynumdle"><h2>{{ $t("message.homeView") }}</h2></ion-label>
          </ion-item>
          <ion-item button @click="menuNavigation('/daily')">
            <ion-icon :icon="todayOutline" color="secondarynumdle" slot="start"></ion-icon>
            <ion-label color="primarynumdle"><h2>{{ $t("message.dailyGame") }}</h2></ion-label>
          </ion-item>
          <ion-item @click="launchUrl('https://resilient-fudge-e9eb14.netlify.app/');" button>
            <ion-icon :icon="textOutline" color="secondarynumdle"  slot="start"></ion-icon>
            <ion-label color="primarynumdle">{{ $t("message.enredoLetras") }}</ion-label>
          </ion-item>
          <ion-item @click="menuNavigation('/kids')" button>
            <ion-icon :icon="peopleOutline" color="secondarynumdle" slot="start"></ion-icon>
            <ion-label color="primarynumdle">{{ $t("message.numdleKids") }}</ion-label>
          </ion-item>
          <ion-item @click="menuNavigation('/training')" button>
            <ion-icon :icon="barbellOutline" color="secondarynumdle" slot="start"></ion-icon>
            <ion-label color="primarynumdle">{{ $t("message.training") }}</ion-label>
          </ion-item>
          <ion-item @click="menuNavigation('/romans')" button>
            <ion-icon :icon="receiptOutline" color="secondarynumdle"  slot="start"></ion-icon>
            <ion-label color="primarynumdle">{{ $t("message.romans") }}</ion-label>
          </ion-item>
          <ion-item @click="menuNavigation('/binaries')" button>
            <ion-icon :icon="hardwareChipOutline" color="secondarynumdle"  slot="start"></ion-icon>
            <ion-label color="primarynumdle">{{ $t("message.binaries") }}</ion-label>
          </ion-item>
          <ion-item @click="menuNavigation('/statistics')" button>
            <ion-icon :icon="podiumOutline" color="secondarynumdle"  slot="start"></ion-icon>
            <ion-label color="primarynumdle">{{ $t("message.myStatistics") }}</ion-label>
          </ion-item>
          <ion-item @click="launchUrl('https://www.buymeacoffee.com/numdle');" button>
            <ion-icon :icon="cafeOutline" color="secondarynumdle"  slot="start"></ion-icon>
            <ion-label color="primarynumdle">{{ $t("message.coffee") }}</ion-label>
          </ion-item>
        </ion-list>
      </ion-content>
    </ion-menu>
  </template>
  <script>
  import {
    IonMenu,IonHeader,IonContent,IonToolbar,IonTitle,IonItem,IonList,IonLabel, menuController
  } from "@ionic/vue";
  import { homeOutline, todayOutline, textOutline, barbellOutline, trophyOutline, receiptOutline, hardwareChipOutline, podiumOutline, logInOutline, cafeOutline, micOutline, peopleOutline } from 'ionicons/icons';

  export default {
    components: {
      IonMenu,IonHeader,IonContent,IonToolbar,IonTitle,IonItem,IonList,IonLabel
    },
    setup() {
      return { homeOutline, todayOutline, textOutline, barbellOutline, trophyOutline, receiptOutline, hardwareChipOutline, podiumOutline, logInOutline, cafeOutline, micOutline, peopleOutline };
    },
    methods:{
      menuNavigation(url){
        menuController.close("app-menu");
        this.$router.push(url);
      },
      launchUrl(url){
        window.open(url, '_blank');
      }
    }
  };
  </script>
  <style scoped>
    .numdle-background-color{
      --background: #07305d;
      --color: white;
    
      --border-bottom: 4px #eaa159;
    
      --min-height: 60px;
    
    }
  
  </style>