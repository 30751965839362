import { createRouter, createWebHistory } from '@ionic/vue-router';
import HomeView from '../views/HomeView.vue';
import DailyView from '../views/DailyView.vue';
import TrainingView from '../views/TrainingView.vue';
import LeagueView from '../views/LeagueView.vue';
import RomansView from '../views/RomansView.vue';
import BinariesView from '../views/BinariesView.vue';
import StatisticsView from '../views/StatisticsView.vue';
import BuyMeCoffeeView from '../views/BuyMeCoffeeView.vue';
import NumdleMediaView from '../views/NumdleMediaView.vue';
import KidsView from '../views/KidsView.vue';
import WordsView from '../views/WordsView.vue';

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    component: HomeView
  },
  {
    path: '/words',
    component: WordsView
  },
  {
    path: '/daily',
    component: DailyView
  },
  {
    path: '/training',
    component: TrainingView
  },
  {
    path: '/kids',
    component: KidsView
  },
  {
    path: '/league',
    component: LeagueView
  },
  {
    path: '/romans',
    component: RomansView
  },
  {
    path: '/binaries',
    component: BinariesView
  },
  {
    path: '/statistics',
    component: StatisticsView
  },
  {
    path: '/coffee',
    component: BuyMeCoffeeView
  },
  {
    path: '/media',
    component: NumdleMediaView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
