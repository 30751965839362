


import GameRepository from '../repository/gameRepository'
const gameRepository = new GameRepository()

export default class HistoryService {
  downloadFile () {
    return (async () => {
      const fileContent = {};
      
      fileContent['lastGame'] = await gameRepository.getLastGame();
      fileContent['playedGames'] = await gameRepository.getPlayedGames();
      fileContent['wonGames'] = await gameRepository.getWonGames();
      fileContent['level'] = await gameRepository.getLevel();
      fileContent['championsLeage'] = await gameRepository.getChampionsLeagueWon();
      fileContent['negativeStreak'] = await gameRepository.getCurrentNegativeStreak(); 
      fileContent['positiveStreak'] = await gameRepository.getCurrentPositiveStreak(); 

      let stringContent = JSON.stringify(fileContent);
      stringContent = window.btoa(stringContent);
      return stringContent;
    })()
  }

  uploadFile (file) {
    return (async () => {
      const historyString = window.atob(window.atob(file));
      const history = JSON.parse(historyString);
      gameRepository.setLastGame(history['lastGame']);
      gameRepository.setPlayedGames(history['playedGames']);
      gameRepository.setWonGames(history['wonGames']);
      gameRepository.setLevel(history['level']);
      gameRepository.setChampionsLeagueWon(history['championsLeage']);
      gameRepository.setCurrentNegativeStreak(history['negativeStreak']);
      gameRepository.setCurrentPositiveStreak(history['positiveStreak']);
      return;
    })()
  }
}
