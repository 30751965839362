<template>
  <ion-page>
    <ion-header>
      <ion-toolbar class="numdle-background-color ion-text-center">
        <ion-buttons @click="openMenu()" slot="start">
          <ion-icon color="secondarynumdle" :icon="menu" slot="start"></ion-icon>
        </ion-buttons>
        <img alt="logo" height="40" src="/assets/logo_peque_numdle.png">
        <ion-title size="small">{{ $t("message.training") }}</ion-title>

        <ion-buttons slot="end">
          <ion-button>
            <ion-icon color="secondarynumdle" slot="icon-only" :icon="personCircle"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <GameView v-on:onGameFinished="gameFinished" pageTitle="Juego" :game="this.game" v-if="played">
      </GameView>
      <div v-if="!played" class="Container">
        <ion-list>
          <ion-item>
            <ion-select @ionChange="currentLevel = $event.detail.value" :placeholder="$t('message.selectTime')">
              <ion-select-option value=120>{{ $t("message.sec120") }}</ion-select-option>
              <ion-select-option value=60>{{ $t("message.sec60") }}</ion-select-option>
              <ion-select-option value=45>{{ $t("message.sec45") }}</ion-select-option>
              <ion-select-option value=30>{{ $t("message.sec30") }}</ion-select-option>
              <ion-select-option value=20>{{ $t("message.sec20") }}</ion-select-option>
            </ion-select>
          </ion-item>
        </ion-list>
        <ion-button  @click="startGame()" class="startDialog" id="open-modal" expand="block">{{ $t("message.start") }}</ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import {
  IonPage, IonHeader, IonToolbar, IonButtons, IonIcon, IonButton,
  menuController, IonList, IonSelect, IonSelectOption, alertController
} from "@ionic/vue";
import { menu } from "ionicons/icons";
import { personCircle } from 'ionicons/icons';
import GameView from "./GameView.vue";
import GameService from "../application/services/gameService";

const gameService = new GameService();


export default {
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonIcon,
    IonButton,
    GameView,
    IonList,
    IonSelect,
    IonSelectOption
},
  props: ["pageTitle"],
  data() {
    return {
      menu,
      currentLevel: '45',
      played: false,
      game: {},
      result: {
        status: '',
        detail: ''
      }
    };
  },
  methods: {
    openMenu() {
      menuController.open("app-menu");
    },
    async startGame() {
      const generatedGame = await gameService.generateTrainigGame(this.currentLevel, 99);
      this.game = generatedGame;
      this.played = true;
    },
    gameFinished(status , steps, time) {
      this.result.detail = steps+' '+this.$t('message.operations')+' '+time+' '+this.$t('message.seconds');
      if (status) {
        this.result.status = this.$t('message.challengeCompleted');
      } else {
        this.result.status = this.$t('message.challengeNotCompleted');
      }
      this.presentAlert(this.result.detail, this.result.status);
      this.played = false;
    }
  },
  setup() {
    const presentAlert = async (detail, status) => {
        const alert = await alertController.create({
          header: '',
          subHeader: detail,
          message: status,
          buttons: ['OK'],
        });

        await alert.present();
      };
    return { personCircle, presentAlert };
  }
};
</script>
<style scoped>
.Container {
  padding-top: 10%;
  text-align: center;
}

.numdle-background-color {
  --background: #07305d;
  --color: white;

  --border-bottom: 4px #eaa159;

  --min-height: 60px;

}

ion-title {
  --color: #eaa159;
}

ion-content {
  --ion-background-color: #07305d;
}

.startDialog {
  --background: #fff;
  --background-hover: #fff;
  --background-activated: #fff;
  --background-focused: #fff;

  --color: #07305d;

  --border-radius: 10px;
  --border-color: #eaa159;
  --border-style: solid;
  --border-width: 3px;

  --box-shadow: 0 2px 6px 0 rgb(0, 0, 0, 0.25);

  --ripple-color: #eaa159;

  --padding-top: 10px;
  --padding-bottom: 10px;

  margin-top: 0px;
  margin-right: 20px;
  margin-left: 20px;
  height: 80px;
  font-size: 20px;

}

.startGame {
  --background: #fff;
  --background-hover: #fff;
  --background-activated: #fff;
  --background-focused: #fff;

  --color: #eaa159;

  --border-radius: 10px;
  --border-color: #07305d;
  --border-style: solid;
  --border-width: 3px;

  --box-shadow: 0 2px 6px 0 rgb(0, 0, 0, 0.25);

  --ripple-color: #07305d;

  --padding-top: 10px;
  --padding-bottom: 10px;

  margin-top: 0px;
  margin-right: 20px;
  margin-left: 20px;
  height: 60px;
  font-size: 20px;

}

ion-modal {
  --height: 50%;
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

ion-modal::part(backdrop) {
  background: rgba(209, 213, 219);
  opacity: 1;
}

ion-modal ion-toolbar {
  --background: #07305d;
  --color: white;
}
ion-list {
    background-color: #eaa159;
    margin-bottom: 10%;
  }
ion-select {
    --placeholder-color: #eaa159;
    --placeholder-opacity: 1;
    width: 100%;
    justify-content: center;
  }

  ion-select::part(placeholder),
  ion-select::part(text) {
    flex: 0 0 auto;
  }

  ion-select::part(placeholder)::first-letter {
    font-size: 24px;
    font-weight: 500;
  }

  ion-select::part(text) {
    color: #eaa159;
  }

  ion-select::part(icon) {
    color: #eaa159;
    opacity: 1;
  }

</style>