import programmedGames from "./programmedGames.json";

export default class GameRepository {
  saveGame (game) {
    return new Promise((resolve) => {
      localStorage.setItem('game', JSON.stringify(game))
      resolve()
    })
  }

  getGame () {
    return new Promise((resolve) => {
      resolve(JSON.parse(localStorage.getItem('game')))
    })
  }

  setLastGame (game) {
    return new Promise((resolve) => {
      localStorage.setItem('lastGame', JSON.stringify(game));
      resolve();
    })
  }

  getLastGame () {
    return new Promise((resolve) => {
      resolve(JSON.parse(localStorage.getItem('lastGame')))
    })
  }

  getProgrammedGame (date, level) {
    return new Promise((resolve) => {
      let game = programmedGames[date];
      if (game !== undefined && game.specialTime === undefined) {
        if(level === 'gold') {
          game.time = 45;
        } else if (level === 'silver') {
          game.time = 60;
        } else if (level === 'bronze') {
          game.time = 75;
        } else if (level === 'stars') {
          game.time = 30;
        } 
      }
      resolve(game);
    })
  }

  setPlayedGames (played) {
    return new Promise((resolve) => {
      localStorage.setItem('playedGames', played)
      resolve()
    })
  }

  getPlayedGames () {
    return new Promise((resolve) => {
      resolve(localStorage.getItem('playedGames'))
    })
  }

  setWonGames (won) {
    return new Promise((resolve) => {
      localStorage.setItem('wonGames', won)
      resolve()
    })
  }

  getWonGames () {
    return new Promise((resolve) => {
      resolve(localStorage.getItem('wonGames'))
    })
  }

  setAverageGames (average) {
    return new Promise((resolve) => {
      localStorage.setItem('averageGames', average)
      resolve()
    })
  }

  getAverageGames () {
    return new Promise((resolve) => {
      resolve(localStorage.getItem('averageGames'))
    })
  }

  getLevel () {
    return new Promise((resolve) => {
      let level = localStorage.getItem('level')
      if (level === null || level === undefined || level === 'undefined') {
        level = 'bronze'
        localStorage.setItem('level', level)
      }
      resolve(level);
    })
  }

  setLevel (level) {
    return new Promise((resolve) => {
      localStorage.setItem('level', level)
      resolve()
    })
  }

  getCurrentPositiveStreak () {
    return new Promise((resolve) => {
      let streak = localStorage.getItem('positiveStreak')
      if (streak === null || streak === undefined || streak === 'undefined') {
        streak = 0;
      }
      resolve(streak);
    })
  }

  setCurrentPositiveStreak (streak) {
    return new Promise((resolve) => {
      localStorage.setItem('positiveStreak', streak)
      resolve()
    })
  }

  getCurrentNegativeStreak () {
    return new Promise((resolve) => {
      let streak = localStorage.getItem('negativeStreak')
      if (streak === null || streak === undefined || streak === 'undefined') {
        streak = 0;
      }
      resolve(streak);
    })
  }

  setCurrentNegativeStreak (streak) {
    return new Promise((resolve) => {
      localStorage.setItem('negativeStreak', streak)
      resolve()
    })
  }

  setChampionsLeagueWon (championsLeague) {
    return new Promise((resolve) => {
      localStorage.setItem('championsLeage', championsLeague)
      resolve()
    })
  }

  getChampionsLeagueWon () {
    return new Promise((resolve) => {
      let championsLeague = localStorage.getItem('championsLeage')
      if (championsLeague === null || championsLeague === undefined || championsLeague === 'undefined') {
        championsLeague = 0;
      }
      resolve(Number(championsLeague));
    })
  }

  saveAdvancedStatistics (statistics) {
    return new Promise((resolve) => {
      localStorage.setItem('statistics', JSON.stringify(statistics))
      resolve()
    })
  }

  getAdvancedStatistics () {
    return new Promise((resolve) => {
      let advancedStatistics = localStorage.getItem('statistics')
      if (advancedStatistics === null || advancedStatistics === undefined || advancedStatistics === 'undefined') {
        advancedStatistics = {
          ratio: {
            totalOps: 0,
            totalTime: 0,
            mean: 0
          },
          operations: {
            suma: {
              totalOps: 0,
              okOps: 0,
              koOps: 0,
              percentage: 0
            },
            resta: {
              totalOps: 0,
              okOps: 0,
              koOps: 0,
              percentage: 0
            },
            multiplicacion: {
              totalOps: 0,
              okOps: 0,
              koOps: 0,
              percentage: 0
            },
            division: {
              totalOps: 0,
              okOps: 0,
              koOps: 0,
              percentage: 0
            }
          }
        }
        resolve(advancedStatistics);
      } else {
        resolve(JSON.parse(advancedStatistics))
      }
    })
  }

}
