<template>
  <ion-page>
    <ion-header>
      <ion-toolbar class="numdle-background-color ion-text-center">
        <ion-buttons @click="openMenu()" slot="start">
          <ion-icon color="secondarynumdle" :icon="menu" slot="start"></ion-icon>
        </ion-buttons>
        <img alt="logo" height="40" src="/assets/logo_peque_numdle.png">
        <ion-title size="small">{{ $t("message.statistics") }}</ion-title>

        <ion-buttons @click="refreshStatistics()" slot="end">
          <ion-button>
            <ion-icon color="secondarynumdle" slot="icon-only"  :icon="refresh"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <Radar v-if="loaded" :data="chartData" :options="chartOptions" />
      <ion-list>
        <ion-item class="numdle-background-color">
          <ion-label slot="start">Ops. por minuto: </ion-label>
          <ion-label slot="end">{{ statistics.ratio }}</ion-label>
        </ion-item>
        <ion-item class="numdle-background-color">
          <ion-label slot="start">Comodines: </ion-label>
          <ion-label slot="end">{{ statistics.championsLeague }}</ion-label>
        </ion-item>
        <ion-item class="numdle-background-color">
          <ion-label slot="start">Racha negativa: </ion-label>
          <ion-label slot="end">{{ statistics.negativeStrike }}</ion-label>
        </ion-item>
        <ion-item class="numdle-background-color">
          <ion-label slot="start">Racha positiva: </ion-label>
          <ion-label slot="end">{{ statistics.positiveStrike }}</ion-label>
        </ion-item>
        <ion-item class="numdle-background-color">
          <ion-label slot="start">Retos jugados: </ion-label>
          <ion-label slot="end">{{ statistics.played }}</ion-label>
        </ion-item>
        <ion-item class="numdle-background-color">
          <ion-label slot="start">Retos ganados: </ion-label>
          <ion-label slot="end">{{ statistics.won }}</ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>
<script>
import {
  IonPage, IonHeader, IonToolbar, IonButtons, IonIcon,
  menuController, IonItem, IonLabel, IonList
} from "@ionic/vue";
import { menu } from "ionicons/icons";
import { refresh } from 'ionicons/icons';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
} from 'chart.js'
import { Radar } from 'vue-chartjs'

import GameService from "../application/services/gameService";

const gameService = new GameService();

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
)

export default {
  components: {
    IonPage, IonHeader, IonToolbar, IonButtons, IonIcon, IonItem, IonLabel, IonList, Radar
  },
  props: ["pageTitle"],
  data() {
    return {
      loaded: false,
      menu,
      statistics: {
        played: 0,
        won: 0,
        average: 0,
        negativeStrike: 0,
        positiveStrike: 0,
        championsLeague: 0,
        ratio: 0
      },
      chartData: null,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            labels: {
              // This more specific font property overrides the global property
              font: {
                size: 18
              }
            }
          }
        },
        scales: {
          r: {
            pointLabels: {
              font: {
                size: 14
              }
            },
            angleLines: {
              color: '#07305d'
            }
          }
        },
        scale: {
          min: 0,
          max: 100,
        }
      }
    };
  },
  methods: {
    openMenu() {
      menuController.open("app-menu");
    },
    refreshStatistics() {
      this.getStatistics();
    },
    getStatistics() {
      gameService.getStatistics().then((statistics) => {
      this.statistics = statistics;
      gameService.getAdvancedStatistics().then((advancedStatistics) => {
        this.statistics.ratio = advancedStatistics.ratio.mean;
        const chartData2 = {};
        chartData2.labels = [
          'Sumas',
          'Restas',
          'Multiplicaciones',
          'Divisiones'
        ];
        chartData2.datasets = [];
        const dataset1 = {};
        dataset1.label = 'Efectividad %';
        dataset1.backgroundColor = 'rgba(234,161,89,0.7)',
        dataset1.pointBackgroundColor = 'rgba(234,161,89,1)',
        dataset1.pointBorderColor = '#fff',
        dataset1.pointHoverBackgroundColor = '#fff',
        dataset1.pointHoverBorderColor = 'rgba(234,161,89,1)',
        dataset1.data = [];
        dataset1.data.push(advancedStatistics.operations.suma.percentage);
        dataset1.data.push(advancedStatistics.operations.resta.percentage);
        dataset1.data.push(advancedStatistics.operations.multiplicacion.percentage);
        dataset1.data.push(advancedStatistics.operations.division.percentage);
        chartData2.datasets.push(dataset1);
        this.chartData = chartData2;
        this.loaded = true;

      });
    });
    }
  },
  setup() {
    return { refresh };
  },
  mounted() {
    this.getStatistics();
  },
};
</script>
<style scoped>
.numdle-background-color {
  --background: #07305d;
  --color: white;

  --border-bottom: 4px #eaa159;

  --min-height: 60px;

}

ion-title {
  --color: #eaa159;
}
</style>