<template>
  <ion-content :fullscreen="false">
        <k-progress
      class="barprogress"
      active
      status="error"
      type="lump"
      :showText="false"
      :border="false"
      :color="['#e4872c', '#e4872c']"
      :cutWidth="1"
      :line-height="20"
      :percent="percentage"
    >
    </k-progress>
    <div class="wrapper">
      <div>
        <div class="number">
          {{ this.throw.number1 }}
        </div>
        <div>
          <img
            v-if="this.throw.operation === '+'"
            id="operation"
            src="../assets/plus_signo.png"
          />
          <img
            v-if="this.throw.operation === '*'"
            id="operation"
            src="../assets/prod_signo.png"
          />
          <img
            v-if="this.throw.operation === '/'"
            id="operation"
            src="../assets/div_signo.png"
          />
          <img
            v-if="this.throw.operation === '-'"
            id="operation"
            src="../assets/minus_signo.png"
          />
        </div>
        <div class="number">
          {{ this.throw.number2 }}
        </div>
        <div class="numberUser">
          {{ this.valueUser }}
        </div>
      </div>
      <div class="keyboard">
        <BinaryKeyboard
          v-if="this.keyboard === 'binary'"
          :key="refresh"
          :initValue="valueUser"
          v-on:onNewNumber="validateThrow"
          v-on:onDeleteNumber="newNumber"
          v-on:onNewDigit="changeUserValue"
        ></BinaryKeyboard>
        <NumericKeyboard
          v-if="this.keyboard === 'decimal'"
          :key="refresh"
          :initValue="valueUser"
          :comodines="comodines"
          v-on:onNewNumber="validateThrow"
          v-on:onNewComodin="decrementComodin"
          v-on:onDeleteNumber="newNumber"
          v-on:onNewDigit="changeUserValue"
        ></NumericKeyboard>
        <RomanNumericKeyboard
          v-if="this.keyboard === 'roman'"
          :key="refresh"
          :initValue="valueUser"
          v-on:onNewNumber="validateThrow"
          v-on:onNewComodin="decrementComodin"
          v-on:onDeleteNumber="newNumber"
          v-on:onNewDigit="changeUserValue"
        ></RomanNumericKeyboard>
      </div>
    </div>

</ion-content>
</template>
<script>
import {
  menuController
} from "@ionic/vue";
import { menu } from "ionicons/icons";
import { personCircle } from 'ionicons/icons';
import RomanNumericKeyboard from "../components/RomanNumericKeyboard.vue";
import BinaryKeyboard from "../components/BinaryKeyboard.vue";
import NumericKeyboard from "../components/NumericKeyboard.vue";
import GameService from "../application/services/gameService";

const gameService = new GameService();

export default {
  components: {
    RomanNumericKeyboard, NumericKeyboard, BinaryKeyboard
  },
  props: ["pageTitle", "game", "comodines"],
  data() {
    return {
      menu,
      percentage: 0,
      timeConsumed: 0,
      totalTime: 0,
      gameEnded: false,
      throw: {
        number1: 0,
        number2: 0,
        operation: "+"
      },
      valueUser: "",
      keyboard: "decimal",
      currentThrow: 0,
      refresh: false,
      notValidSuccessThrow: 0,
      statistics: {
        suma: {
          total: 0,
          correct: 0
        },
        multiplicacion: {
          total: 0,
          correct: 0
        },
        division: {
          total: 0,
          correct: 0
        },
        resta: {
          total: 0,
          correct: 0
        }
      }
    };
  },
  methods: {
    openMenu() {
      menuController.open("app-menu");
    },
    endGameKO() {
      this.$emit('onGameFinished', false, this.currentThrow, this.timeConsumed, this.statistics);
    },
    endGameOK() {
      this.$emit('onGameFinished', true, this.currentThrow, this.timeConsumed, this.statistics);
    },
    validateThrow(value) {
      this.valueUser = "";
      const self = this;
      let correctValue = null;

      if (this.game["results"] !== undefined) {
        correctValue = this.game.results[this.currentThrow];
      }
      gameService
        .validateThrow(
          this.throw.number1,
          this.throw.number2,
          this.throw.operation,
          value,
          correctValue
        )
        .then((result) => {
          this.calculateStatistics(true, this.throw.operation);
          self.throw.number1 = result;
          self.currentThrow = self.currentThrow + 1;
          if (self.timeOut) {
            self.notValidSuccessThrow = self.notValidSuccessThrow + 1;
          }
          if (self.currentThrow === self.game.operations.length) {
            if (self.timeOut) {
              self.endGameKO();  // KO
            } else {      
              self.endGameOK(); // OK
            }
          } else {
            self.showNext();
          }
        })
        .catch(() => {
          this.valueUser = "";
          this.calculateStatistics(false, this.throw.operation);
        });
    },
    showNext() {
      if (this.currentThrow === 0) {
        this.throw.number1 = this.game.numbers[0];
        this.throw.number2 = this.game.numbers[1];
      } else {
        let index = this.currentThrow + 1;
        this.throw.number2 = this.game.numbers[index];
      }
      this.throw.operation = this.game.operations[this.currentThrow];
    },
    newNumber(newValue) {
      this.valueUser = newValue;
      this.refresh = !this.refresh;
    },
    decrementComodin() {
      this.$emit('onUsedComodin');
    },
    deleteValue() {
      this.refresh = !this.refresh;
      this.valueUser = "";
    },
    changeUserValue(value) {
      this.valueUser = value;
    },
    calculateStatistics(valid, operation) {
      if (operation === '+') {
        this.statistics.suma.total = this.statistics.suma.total + 1;
        if (valid) {
          this.statistics.suma.correct = this.statistics.suma.correct + 1;
        }
      } else if (operation === '-') {
        this.statistics.resta.total = this.statistics.resta.total + 1;
        if (valid) {
          this.statistics.resta.correct = this.statistics.resta.correct + 1;
        }
      } else if (operation === '*') {
        this.statistics.multiplicacion.total = this.statistics.multiplicacion.total + 1;
        if (valid) {
          this.statistics.multiplicacion.correct = this.statistics.multiplicacion.correct + 1;
        }
      } else if (operation === '/') {
        this.statistics.division.total = this.statistics.division.total + 1;
        if (valid) {
          this.statistics.division.correct = this.statistics.division.correct + 1;
        }
      }
    },
    startTimer() {
      this.totalTime = this.game.time;
      const self = this;
      setInterval(function() {
        self.timeConsumed = self.timeConsumed + 1;
        if (
          self.timeConsumed >= self.totalTime &&
          !self.gameEnded &&
          !self.timeOut
        ) {
          self.endGameKO();
        }
        if (self.timeConsumed <= self.totalTime && !self.gameEnded) {
          self.percentage = Math.trunc(
            (self.timeConsumed / self.totalTime) * 100
          );
        }
      }, 1000);
    },
  },
  setup() {
    return { personCircle };
  },
  mounted() {
    this.keyboard = this.game.keyboard;
    this.showNext();
    this.startTimer();
  }
};
</script>
<style scoped>
.numdle-background-color{
  --background: #07305d;
  --color: white;

  --border-bottom: 4px #eaa159;

  --min-height: 60px;

}

ion-title {
--color: #eaa159;
}

.barprogress {
  width: 100%;
  padding-right: 20px;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.number {
  font-family: Helvetica, Arial;
  font-size: 2em;
  color: #ffffff;
}
.numberUser {
  font-family: Helvetica, Arial;
  font-size: 2em;
  border-top: #e4872c 1px solid;
  color: #e4872c;
  text-align: center;
}
.wrapper {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
  grid-auto-rows: 1fr;
}

.keyboard {
  width: 100%;
  position: fixed;
  bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>