<template>
  <ion-page>    
  <ion-header>
      <ion-toolbar class="numdle-background-color ion-text-center">
          <ion-buttons @click="openMenu()" slot="start">
              <ion-icon color="secondarynumdle" :icon="menu" slot="start"></ion-icon>
          </ion-buttons>
          <img alt="logo" height="40"  src="/assets/logo_peque_numdle.png" > 
          <ion-title size="small">Numdle League</ion-title>

          <ion-buttons slot="end">
              <ion-button>
                  <ion-icon color="secondarynumdle" slot="icon-only" :icon="personCircle"></ion-icon>
              </ion-button>
          </ion-buttons>
      </ion-toolbar>
  </ion-header>
  <ion-content :fullscreen="true">
    
    <ion-card>
      <ion-card-header>
        <ion-card-title>Numdle League</ion-card-title>
        <ion-card-subtitle>La liga de Numdle</ion-card-subtitle>
      </ion-card-header>
    </ion-card>

</ion-content>
  </ion-page>
</template>
<script>
import {
  IonPage,IonHeader,IonToolbar,IonButtons,IonIcon,
  menuController, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle
} from "@ionic/vue";
import { menu } from "ionicons/icons";
import { personCircle } from 'ionicons/icons';

export default {
  components: {
    IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonPage,IonHeader,IonToolbar,IonButtons,IonIcon,
  },
  props: ["pageTitle"],
  data() {
    return {
      menu,
    };
  },
  methods: {
    openMenu() {
      menuController.open("app-menu");
    },
  },
  setup() {
    return { personCircle };
  }
};
</script>
<style scoped>
.numdle-background-color{
  --background: #07305d;
  --color: white;

  --border-bottom: 4px #eaa159;

  --min-height: 60px;

}

ion-title {
--color: #eaa159;
}

</style>