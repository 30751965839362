<template>
  <div class="calculator">
    <button @click="add('8')" class="box eight disable-dbl-tap-zoom">8</button>
    <button @click="add('5')" class="box five disable-dbl-tap-zoom">5</button>
    <button @click="add('6')" class="box six disable-dbl-tap-zoom">6</button>
    <button @click="add('7')" class="box seven disable-dbl-tap-zoom">7</button>
    <button @click="add('4')" class="box four disable-dbl-tap-zoom">4</button>
    <button @click="add('9')" class="box nine disable-dbl-tap-zoom">9</button>
    <button @click="add('1')" class="box one disable-dbl-tap-zoom">1</button>
    <button @click="add('2')" class="box two disable-dbl-tap-zoom">2</button>
    <button @click="add('3')" class="box three disable-dbl-tap-zoom">3</button>
    <button :key="refresh" v-if="currentComodines > 0" @click="add('∞')" class="box infinit disable-dbl-tap-zoom">∞</button>
    <button @click="add('0')" class="box zero disable-dbl-tap-zoom">0</button>
    <button @click="add('.')" class="box dot disable-dbl-tap-zoom">,</button>
    <button @click="add('-')" class="box minus disable-dbl-tap-zoom">-</button>
    <button @click="deleteCharacter" class="box delete disable-dbl-tap-zoom">←</button>
    <button @click="send" class="box intro disable-dbl-tap-zoom">OK</button>

  </div>
</template>
<script >

export default {
  name: 'NumericKeyboard',
  data () {
    return {
      currentValue: this.initValue,
      currentComodines: this.comodines,
      refresh: false
    }
  },
  props: {
    initValue: String,
    comodines: Number
  },
  methods: {
    send () {
      let valueToValidate = this.currentValue;
      this.currentValue = '';

      if (this.currentComodines > 0 && valueToValidate === '∞') {
        this.currentComodines = this.currentComodines - 1;
        this.$emit('onNewComodin');
      }
      this.refresh = !this.refresh;
      this.$emit('onNewNumber', valueToValidate);
    },
    deleteCharacter () {
      let numberString = this.currentValue.toString();
      if (numberString.length>0) {
        this.currentValue = this.currentValue.toString().slice(0, -1);
        this.$emit('onDeleteNumber', this.currentValue);
      }
    },
    clean () {
      this.currentValue = '';
    },
    add (value) {
      this.currentValue = this.currentValue + value;
      this.$emit('onNewDigit', this.currentValue);
    }
  },
  created() { 
    document.addEventListener('keydown', (event) => {
        let keyValue = event.key;
        let codeValue = event.code;
        if (codeValue === 'Numpad0' || codeValue === 'Digit0') {
          this.add(0);
        } else if (codeValue === 'Numpad1' || codeValue === 'Digit1') {
          this.add(1);
        } else if (codeValue === 'Numpad2' || codeValue === 'Digit2') {
          this.add(2);
        } else if (codeValue === 'Numpad3' || codeValue === 'Digit3') {
          this.add(3);
        } else if (codeValue === 'Numpad4' || codeValue === 'Digit4') {
          this.add(4);
        } else if (codeValue === 'Numpad5' || codeValue === 'Digit5') {
          this.add(5);
        } else if (codeValue === 'Numpad6' || codeValue === 'Digit6') {
          this.add(6);
        } else if (codeValue === 'Numpad7' || codeValue === 'Digit7') {
          this.add(7);
        } else if (codeValue === 'Numpad8' || codeValue === 'Digit8') {
          this.add(8);
        } else if (codeValue === 'Numpad9' || codeValue === 'Digit9') {
          this.add(9);
        } else if (codeValue === 'Slash' || codeValue === 'NumpadSubtract' || keyValue === '-') {
          this.add("-");
        } else if (codeValue === 'Comma' || codeValue === 'NumpadDecimal' || keyValue === ',' || keyValue === '.') {
          this.add(".");
        } else if (keyValue === 'Enter') {
          this.send();
        } else if (codeValue === 'Backspace') {
          this.deleteCharacter();
        } 
      }, false);
    }
}
</script>
<style>
.calculator {
    display: grid;
    grid-gap: 2px;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    color: #444;
    padding-right: 10px;
  }

.disable-dbl-tap-zoom {
  touch-action: manipulation;
}

.box {
  background-color: #f0bb86;
  color: #07305d;
  border-radius: 4px;
  padding: 20px;
  font-size: 140%;

}
.box-comodin {
  background-color: #f0bb86;
  color: #FFFFFF;
  border-radius: 4px;
  padding: 20px;
  font-size: 140%;

}
.box:active {
  background-color:white;    
}

.eight {
  grid-area: 1 / 2 / 2 / 3;
}
.five {
  grid-area: 2 / 2 / 3 / 3;
}
.six {
  grid-area: 2 / 3 / 3 / 4;
}
.seven {
  grid-area: 1 / 1 / 2 / 2;
}
.four {
  grid-area: 2 / 1 / 3 / 2;
}
.nine {
  grid-area: 1 / 3 / 2 / 4;
}
.one {
  grid-area: 3 / 1 / 3 / 2;
}
.infinit {
  grid-area: 4 / 1 / 4 / 2;
}
.two {
  grid-area: 3 / 2 / 3 / 3;
}
.three {
  grid-area: 3 / 3 / 3 / 4;
}
.dot {
  grid-area: 4 / 3 / 4 / 3;
}
.zero {
  grid-area: 4 / 2 / 4 / 3;
}
.delete {
  grid-area: 1 / 4 / 1 / 4;
}
.intro {
  grid-area: 3 / 4 / 3 / 4;
  color: green;
}
.minus {
  grid-area: 2 / 4 / 3 / 4;
}
.button_keyboard {
  display: inline-block;   /* this shouldn't be needed on a button though */
  width: 100%;
}
</style>