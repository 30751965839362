
import GameRepository from '../repository/gameRepository'
import * as Romanice from 'romanice';
const { romanice } = Romanice;
const standardConverter = romanice();
const gameRepository = new GameRepository();
const NUM_GAMES_TO_CHANGE_LEVEL = 3;
const NUM_OPERATIONS = 7;
let MAX_VALUE = 9;
export default class GameService {
  generateDailyGame () {
    return (async () => {
      let game = {};
      let level = await gameRepository.getLevel();
      let programmedGame = await gameRepository.getProgrammedGame(getStringDate(), level);
      const seed = getCurrentSeed();

      if (programmedGame === undefined) {
        game = generateRandomGame();
        if(level === 'gold') {
          game.time = 45;
        } else if (level === 'silver') {
          game.time = 60;
        } else if (level === 'bronze') {
          game.time = 75;
        } else if (level === 'stars') {
          game.time = 30;
        }
      } else {
        game = programmedGame;
      }
      game.id = seed;
      game.date = getStringDate();
      game.finished = false;

      await gameRepository.saveGame(game)
      return game;
    })()
  }

  generateRomanGame (time) {
    let game = {
      "keyboard" : "roman",
      "operations" : [],
      "numbers" : [],
      "results" : []
    };
    MAX_VALUE = 99;
    game.time = Number.parseInt(time);
    const operations = this.generateOperationsRomans(3);
    const numbers = [];
    let totalAcumulated = [];
      let total = 0;
      for (let i=0; i<operations.length; i++) {
        if (i===0) {
          total = this.getRandomInt(1,MAX_VALUE);
          numbers.push(total);
        }
        numbers.push(this.getSecondNumber(operations[i], total));
        total = eval(total+''+operations[i]+''+numbers[numbers.length-1]);
        totalAcumulated.push(total);
      }
    
    game.operations = operations;
    game.numbers = numbers.map(number => {
      return this.romanize(number);
    });
    game.results = totalAcumulated.map(number => {
      return this.romanize(number);
    });
    return game;
  }

  generateBinaryGame (time) {
    let game = {
      "keyboard" : "binary",
      "operations" : [],
      "numbers" : [],
      "results" : []
    };
    MAX_VALUE = 9;
    game.time = Number.parseInt(time);
    const operations = this.generateOperationsNoSubstraction(3);
    const numbers = [];
    let totalAcumulated = [];
      let total = 0;
      for (let i=0; i<operations.length; i++) {
        if (i===0) {
          total = this.getRandomInt(1,MAX_VALUE);
          numbers.push(total);
        }
        numbers.push(this.getSecondNumber(operations[i], total));
        total = eval(total+''+operations[i]+''+numbers[numbers.length-1]);
        totalAcumulated.push(total);
      }
    
    game.operations = operations;
    game.numbers = numbers.map(number => {
      return this.toBinary(number);
    });
    game.results = totalAcumulated.map(number => {
      return this.toBinary(number);
    });
    return game;
  }

  romanize (num) {
    try {
      if (isNaN(num))
      return NaN;
  return standardConverter.toRoman(num);
    } catch (e) {
      this.generateRomanGame();
    }
  }

  toBinary (num) {
    const number = parseInt(num);
    return number.toString(2);
  }

  generateTrainigGame (time, maxvalue) {
    return (async () => {
      let game = {
        "keyboard" : "decimal",
        "operations" : [],
        "numbers" : [],
        "time": 30
      };
      MAX_VALUE = maxvalue;
      game.time = time;
      const operations = this.generateOperations(NUM_OPERATIONS);
      const numbers = [];
      let totalAcumulated = [];
      let total = 0;
      for (let i=0; i<operations.length; i++) {
        if (i===0) {
          total = this.getRandomInt(1,MAX_VALUE);
          numbers.push(total);
        }
        numbers.push(this.getSecondNumber(operations[i], total));
        total = eval(total+''+operations[i]+''+numbers[numbers.length-1]);
        totalAcumulated.push(total);
      }
    
      game.operations = operations;
      game.numbers = numbers;
      return game;
    })()
  }

  generateOperations(numOperations) {
    const randomOperations = [];
    for (let i=0;i<numOperations;i++) {
      let operation = '';
      let randomNumber = Math.floor(Math.random()*100);
      if (randomNumber <= 40) {
        operation = '+';
      } else if(randomNumber <= 80) {
        operation = '-';
      } else if(randomNumber <= 90) {
        operation = '*';
      } else if(randomNumber <= 100) {
        operation = '/';
      }
      randomOperations.push(operation);
    }
    return randomOperations;
    
  }

  generateOperationsNoSubstraction(numOperations) {
    const randomOperations = [];
    for (let i=0;i<numOperations;i++) {
      let operation = '';
      let randomNumber = Math.floor(Math.random()*100);
      if (randomNumber <= 40) {
        operation = '+';
      } else if(randomNumber <= 90) {
        operation = '*';
      } else if(randomNumber <= 100) {
        operation = '/';
      }
      randomOperations.push(operation);
    }
    return randomOperations;
  }

  generateOperationsRomans(numOperations) {
    const randomOperations = [];
    for (let i=0;i<numOperations;i++) {
      let operation = '';
      let randomNumber = Math.floor(Math.random()*100);
      if (randomNumber <= 100) {
        operation = '+';
      }
      randomOperations.push(operation);
    }
    return randomOperations;
  }

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
  }
  
  getSecondNumber(operation, firstNumber) {
    if (operation === '+') {
      return this.getRandomInt(Math.abs(firstNumber), (MAX_VALUE+1)-Math.abs(firstNumber));
    } else if (operation === '-') {
      if (firstNumber > 0) {
        return this.getRandomInt(1, MAX_VALUE);
      } else {
        return this.getRandomInt(Math.abs(firstNumber), (MAX_VALUE+1)-Math.abs(firstNumber));
      } 
    } else if (operation === '*') {
      return this.getRandomInt(1, 10);
    } else if (operation === '/') {
      const divisors = this.listDivisors(firstNumber);
      return divisors[this.getRandomInt(0,divisors.length-1)];
    }
  }

  /* 
	 * Returns the list of divisors (in ascending order) of the given integer.
	 * Examples:
	 * - listDivisors(1) = [1].
	 * - listDivisors(5) = [1, 5].
	 * - listDivisors(12) = [1, 2, 3, 4, 6, 12].
	 */
listDivisors(n) {
  if (n < 1)
    throw new RangeError("Argument error");
  let small = [];
  let large = [];
  const end = Math.floor(Math.sqrt(n));
  for (let i = 1; i <= end; i++) {
    if (n % i == 0) {
      small.push(i);
      if (i * i != n)  // Don't include a square root twice
        large.push(n / i);
    }
  }
  large.reverse();
  return small.concat(large);
}
  

  getGame () {
    return (async () => {
      const game = await gameRepository.getGame();
      return game
    })()
  }

  setGamePlayed (game) {
    return (async () => {
      let level = await gameRepository.getLevel();
      let played = await gameRepository.getPlayedGames();
      if (played === null) {
        played = 0;
      }
      played = parseInt(played) + 1;
      let won = await gameRepository.getWonGames();
      if (won === null) {
        won = 0;
      } 
      let average = await gameRepository.getAverageGames();
      if (game.won) {
        won = parseInt(won) + 1;
        if (average === null) {
          average = game.time;
        } else {
          average = Math.trunc((parseInt(average) + parseInt(game.time))/2);
        }
        if (game.time <= getLevelTime(level)) {
          let streakPositive = await gameRepository.getCurrentPositiveStreak();
          streakPositive = parseInt(streakPositive) + 1;
          await gameRepository.setCurrentPositiveStreak(streakPositive);
          await gameRepository.setCurrentNegativeStreak(0);
        }
      } else if (game.keyboard !== 'roman' && game.keyboard !== 'binary'){
        await gameRepository.setCurrentPositiveStreak(0);
        let streakNegative = await gameRepository.getCurrentNegativeStreak();
        streakNegative = parseInt(streakNegative) + 1;
        await gameRepository.setCurrentNegativeStreak(streakNegative);
      }
      await gameRepository.setWonGames(won);
      await gameRepository.setPlayedGames(played);
      await gameRepository.setAverageGames(average);
      await gameRepository.setLastGame(game);

      return
    })()
  }

  getGamePlayed () {
    return (async () => {
      const game = await gameRepository.getLastGame();
      return game;
    })()
  }

  nextLevel (currentLevel) {
    return (async () => {
      let nextLevel = currentLevel;
      let negativeStreak = await gameRepository.getCurrentNegativeStreak();
      let positiveStreak = await gameRepository.getCurrentPositiveStreak();

      if(positiveStreak == NUM_GAMES_TO_CHANGE_LEVEL) {
        if (currentLevel === 'bronze') {
          nextLevel = 'silver';
        } else if (currentLevel === 'silver') {
          nextLevel = 'gold';
        } else if (currentLevel === 'gold') {
          nextLevel = 'stars';
        } else if (currentLevel === 'stars') {
          nextLevel = 'stars';
          let championsLeague = await gameRepository.getChampionsLeagueWon();
          championsLeague = championsLeague + 1;
          await gameRepository.setChampionsLeagueWon(championsLeague);
        }
        await gameRepository.setCurrentNegativeStreak(0);
        await gameRepository.setCurrentPositiveStreak(0);
        await gameRepository.setLevel(nextLevel);
      } else if (negativeStreak == NUM_GAMES_TO_CHANGE_LEVEL) {
        if (currentLevel === 'gold') {
          nextLevel = 'silver';
        } else if (currentLevel === 'silver') {
          nextLevel = 'bronze';
        } else if (currentLevel === 'stars') {
          nextLevel = 'gold';
        } else if (currentLevel === 'bronze') {
          nextLevel = 'bronze';
        }
        await gameRepository.setCurrentNegativeStreak(0);
        await gameRepository.setCurrentPositiveStreak(0);
        await gameRepository.setLevel(nextLevel);
      }
      return nextLevel;
    })() 
  }

  getStatistics () {
    return (async () => {
      const statistics = {};
      let played = await gameRepository.getPlayedGames();
      let won = await gameRepository.getWonGames();
      let average = await gameRepository.getAverageGames();
      statistics.played = played;
      statistics.won = won;
      statistics.average = average;
      statistics.positiveStrike = await gameRepository.getCurrentPositiveStreak();
      statistics.negativeStrike = await gameRepository.getCurrentNegativeStreak();
      statistics.championsLeague = await gameRepository.getChampionsLeagueWon();
      return statistics;
    })()
  }

  getAdvancedStatistics () {
    return (async () => {
      let advancedStatistics = await gameRepository.getAdvancedStatistics();
      return advancedStatistics;
    })()
  }

  updateComodines (comodines) {
    return (async () => {
      await gameRepository.setChampionsLeagueWon(comodines);
      return;
    })()
  }
  saveAdvancedStatistics(steps, time, statistics) {
    return (async () => {
      const advancedStatistics = await gameRepository.getAdvancedStatistics();
      advancedStatistics.ratio.totalOps = advancedStatistics.ratio.totalOps + steps;
      advancedStatistics.ratio.totalTime = advancedStatistics.ratio.totalTime + time;
      advancedStatistics.ratio.mean = Math.trunc((60*advancedStatistics.ratio.totalOps)/advancedStatistics.ratio.totalTime);

      advancedStatistics.operations.suma.totalOps = advancedStatistics.operations.suma.totalOps + statistics.suma.total;
      advancedStatistics.operations.suma.okOps = advancedStatistics.operations.suma.okOps + statistics.suma.correct;
      advancedStatistics.operations.suma.koOps = advancedStatistics.operations.suma.koOps + (statistics.suma.total - statistics.suma.correct);
      advancedStatistics.operations.suma.percentage = Math.trunc((advancedStatistics.operations.suma.okOps/advancedStatistics.operations.suma.totalOps) * 100); 

      advancedStatistics.operations.resta.totalOps = advancedStatistics.operations.resta.totalOps + statistics.resta.total;
      advancedStatistics.operations.resta.okOps = advancedStatistics.operations.resta.okOps + statistics.resta.correct;
      advancedStatistics.operations.resta.koOps = advancedStatistics.operations.resta.koOps + (statistics.resta.total - statistics.resta.correct);
      advancedStatistics.operations.resta.percentage = Math.trunc((advancedStatistics.operations.resta.okOps/advancedStatistics.operations.resta.totalOps) * 100); 

      advancedStatistics.operations.multiplicacion.totalOps = advancedStatistics.operations.multiplicacion.totalOps + statistics.multiplicacion.total;
      advancedStatistics.operations.multiplicacion.okOps = advancedStatistics.operations.multiplicacion.okOps + statistics.multiplicacion.correct;
      advancedStatistics.operations.multiplicacion.koOps = advancedStatistics.operations.multiplicacion.koOps + (statistics.multiplicacion.total - statistics.multiplicacion.correct);
      advancedStatistics.operations.multiplicacion.percentage = Math.trunc((advancedStatistics.operations.multiplicacion.okOps/advancedStatistics.operations.multiplicacion.totalOps) * 100); 


      advancedStatistics.operations.division.totalOps = advancedStatistics.operations.division.totalOps + statistics.division.total;
      advancedStatistics.operations.division.okOps = advancedStatistics.operations.division.okOps + statistics.division.correct;
      advancedStatistics.operations.division.koOps = advancedStatistics.operations.division.koOps + (statistics.division.total - statistics.division.correct);
      advancedStatistics.operations.division.percentage = Math.trunc((advancedStatistics.operations.division.okOps/advancedStatistics.operations.division.totalOps) * 100); 

      await gameRepository.saveAdvancedStatistics(advancedStatistics);
      return;
    })()
  }
  validateThrow (number1, number2, operation, userValue, correctValue) {
    return (async (resolve, reject) => {
      let resultOperation = false;
      if (correctValue === null) {
        number1 = parseFloat(number1);
        number2 = parseFloat(number2);
        if (operation == '*') {
          resultOperation = (number1*number2 === parseFloat(userValue));
          if (userValue == '∞') {
            resultOperation = true;
            userValue = number1*number2;
          }
        } else if (operation == '/') {
          resultOperation = (number1/number2 === parseFloat(userValue));
          if (userValue == '∞') {
            resultOperation = true;
            userValue = number1/number2;
          }
        } else if (operation == '+') {
          resultOperation = (number1+number2 === parseFloat(userValue));
          if (userValue == '∞') {
            resultOperation = true;
            userValue = number1+number2;
          }
        } else if (operation == '-') {
          resultOperation = (number1-number2 === parseFloat(userValue));
          if (userValue == '∞') {
            resultOperation = true;
            userValue = number1-number2;
          }
        } 
      } else {
        if(correctValue === String(userValue)) {
          resultOperation = true;
        } else if (userValue == '∞'){
          resultOperation = true;
          userValue = correctValue;
        } else {
          resultOperation = false;
        }  
      }
      if (resultOperation) {
        return userValue;
      } else {
        reject()
      }
    })()
  }

  isCurrentGamePlayed() {
    return (async () => {
      const game = await gameRepository.getLastGame();
      if (game === null || game === undefined || game.id === null || game.id != getCurrentSeed()) {
        return false;
      } else {
        return true;
      }
    })()
  }
}

function getLevelTime (level){
  if (level === 'gold') {
    return 45;
  } else if (level === 'silver') {
    return 60;
  } else if (level === 'bronze') {
    return 75;
  } else if (level === 'stars') {
    return 25;
  }
}

function getCurrentSeed() {
  const stringDate = getStringDate();
  const seed = new Date(stringDate);
  return btoa(seed.getTime());
}

function getStringDate() {
  const date = new Date();
  const stringDate = ''.concat(date.getFullYear()).concat('/').concat(date.getMonth()+1).concat('/').concat(date.getDate())
  return stringDate;
}



function generateRandomGame() {
  const OPERATIONS_SEQUENCE = ['-','-','*','+','-','*','+']

  const numbers = [];

  let number1 = generateRandomInterval(10, 20);

  let number2 = generateRandomInterval(20, 30);

  let number3 = generateRandomInterval(10, 30);

  let number4 = generateRandomInterval(1, 9);

  let number5 = generateRandomInterval(10, 6);


  let number6 = generateRandomInterval(1, 10);

  let number7 = generateRandomInterval(1, 10);

  let number8 = generateRandomInterval(10, 20);

  numbers.push(number1);
  numbers.push(number2);
  numbers.push(number3);
  numbers.push(number4);
  numbers.push(number5);
  numbers.push(number6);
  numbers.push(number7);
  numbers.push(number8);

  const game = {};
  game.numbers = numbers;
  game.operations = OPERATIONS_SEQUENCE;
  game.keyboard = 'decimal'; 

  return game;

}

function generateRandomInterval(min, max) {
  return Math.floor((Math.random()*(max-min+1))+min);
}
